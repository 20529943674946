import { gql } from "@apollo/client";

export const GET_CUSTOMER_SUITABLE_RECIPES_QUERY = gql`
	query ($id: Int $token: String) {
		customer(id: $id token: $token) {
			pets {
				id
				suitable_recipes {
					id
					name
					handle
					product_id
					protein
					discontinued
					category
					product {
						title
						image
						tags
						variants {
							id
							title
							sku
							weight
							price
						}
					}
				}
				suitable_treats: treats {
					id
					name
					handle
					product_id
					discontinued
					suitable
					type
				}
			}
		}
	}
`;

export const GET_CUSTOMER_HEALTH_REPORTS = gql`
	query ($id: Int) {
		customer(id: $id) {
			pets {
				id
				healthReports {
					behavioural {
						anxious_around_dogs
						anxious_around_people
						scared_of_loud_noises
						excessive_lead_pulling
						barks_cries
						separation_anxiety
						destructive
						nervous
						overall
					}
					coat {
						thinning_coat
						excessive_malting
						coarse_coat
						patchy_fur
						flaky_skin
						dry_skin
						tear_stains
						itchy_skin
						overall
					}
					digestion {
						regular_vomiting
						sensitive_stomach
						coughing
						retching
						scooting
						anal_gland_issues
						regular_wind
						constipation
						overall
					}
					mobility {
						joint_issues
						a_little_stiff
						struggles_moving
						very_energetic
						very_docile
						overall
					}
					oral {
						dark_gums
						smelly_breath
						pale_gums
						yellow_or_brown_teeth
						dirty_tongue
						lost_teeth
						plaque_build_up
						dry_gums
						spotty_gums
						overall
					}
					stools
					size
					created_at
					updated_at
				}
			}
		}
	}
`;

export const GET_SINGLE_CUSTOMER_DELIVERY_QUERY = gql`
	query singleDelivery($delivery_id: Int) {
		singleDelivery(delivery_id: $delivery_id) {
			id
			delivery_date
			status
			stripe_error
			total
			delivery
			address_id
			new_address_id
			discount
			processing_date
			is_locked
			scoop
			recycling_label
			discount
			manual
			billing_date
			price
			discount_code
			attempts
			primary_items {
				id
				itemable_id
				itemable_type
				title
				variant_title
				product_id
				variant_id
				image
				type
				uses
				quantity
				price
				default
				one_off
				recipe {
					handle
					protein
					category
					discontinued
					subtitle
					product {
						title
						image
						tags
						variants {
							id
							title
							sku
							weight
							price
						}
					}
				}
				created_at
				updated_at
			}
			secondary_items {
				id
				itemable_id
				itemable_type
				title
				product_id
				variant_id
				image
				type
				uses
				quantity
				price
				default
				created_at
				updated_at
				one_off
				recipe {
					handle
					protein
					category
					discontinued
					subtitle
					product {
						title
						image
						tags
						seasonal_start_date
						seasonal_end_date
						variants {
							id
							title
							sku
							weight
							price
						}
					}
				}
			}
		}
	}
`;

export const GET_CUSTOMER_DELIVERIES_QUERY = gql`
	query ($id: Int $token: String) {
		customer(id: $id token:$token) {
			deliveries {
				id
				delivery_date
				status
				stripe_error
				total
				delivery
				address_id
				new_address_id
				discount
				processing_date
				is_locked
				scoop
				recycling_label
				discount
				manual
				billing_date
				price
				discount_code
				attempts
				primary_items {
					id
					itemable_id
					itemable_type
					title
					variant_title
					product_id
					variant_id
					image
					type
					uses
					quantity
					price
					default
					one_off
					recipe {
						handle
						protein
						category
						discontinued
						subtitle
						product {
							title
							image
							tags
							variants {
								id
								title
								sku
								weight
								price
							}
						}
					}
					created_at
					updated_at
				}
				secondary_items {
					id
					sample
					itemable_id
					itemable_type
					title
					product_id
					variant_id
					image
					type
					uses
					quantity
					price
					default
					created_at
					updated_at
					one_off
					recipe {
						handle
						protein
						category
						discontinued
						subtitle
						product {
							title
							image
							tags
							seasonal_start_date
							seasonal_end_date
							variants {
								id
								title
								sku
								weight
								price
							}
						}
					}
				}
			}
			pets {
				id
				name
				breed
				profile_picture
				gender
				subscription {
					active
					deliveries {
						id
						delivery_date
						status
						stripe_error
						total
						delivery
						address_id
						new_address_id
						discount
						processing_date
						is_locked
						scoop
						recycling_label
						discount
						manual
						billing_date
						price
						discount_code
						attempts
						primary_items {
							id
							itemable_id
							itemable_type
							title
							variant_title
							product_id
							variant_id
							image
							type
							uses
							quantity
							price
							default
							one_off
							recipe {
								handle
								protein
								category
								discontinued
								subtitle
								product {
									title
									image
									tags
									variants {
										id
										title
										sku
										weight
										price
									}
								}
							}
							created_at
							updated_at
						}
						secondary_items {
							sample
							id
							itemable_id
							itemable_type
							title
							product_id
							variant_id
							image
							type
							uses
							quantity
							price
							default
							created_at
							updated_at
							one_off
							recipe {
								handle
								protein
								category
								subtitle
								discontinued
								product {
									title
									image
									tags
									seasonal_start_date
									seasonal_end_date
									variants {
										id
										title
										sku
										weight
										price
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_FULL_CUSTOMER_QUERY = gql`
	query ($id: Int $token: String) {
		customer(id: $id token: $token) {
			id
			shopify_id
			state
			email
			first_name
			ambassador_set_at
			last_name
			stripe_customer_id
			default_shipping_method
			available_shipping_methods
			phone
			contact_via_email
			created_at
			paymentMethod {
				expiry_date
				last_four_digits
				brand
				country
				funding
				payment_id
				paypal_email
				paypal_id
			}
			paymentMethods {
				expiry_date
				last_four_digits
				brand
				country
				funding
				payment_id
				paypal_email
				paypal_id
			}
			addresses {
				shopify_id
				id
				customer_id
				line_1
				line_2
				city
				province
				postcode
				country
				phone
			}
			referral {
				credit
			}
			pets {
				profile_picture
				id
				name
				created_at
				updated_at
				gender
				allergy_count
				allergies
				issues_count
				issues
				neutered
				fussy
				size
				breed
				age
				weight
				species
				activity_level
				treats_fed
				life_stage
				life_stage_enabled
				calculated_food_scoops
				calculated_water_scoops
				recipe_category
				working_dog
				digestion
				behavioural
				stools
				improve_health
				mobility
				oral
				coat
				suitable_recipes {
					handle
					category
				}
				treats {
					id
					name
					handle
					product_id
					suitable
					discontinued
					product {
						title
						image
						tags
						variants {
							id
							title
							sku
							weight
							price
						}
					}
				}
				subscription {
					tests
					completed_deliveries_count
					id
					address_id
					new_address_id
					price
					total
					delivery
					sub_length
					base_price
					base_size
					active
					use_per_subscription
					set_limit_amount
					discount_code_usage
					scoops_food
					scoops_water
					discount
					discount_code
					order_2_and_3_discount_test
					order_2_and_3_discount_test_10
					order_4_to_6_discount_test
					extra_credit
					cancellation_date
					cancellation_reason
					reactive_date
					reminder_offset
					selected_plan
					primary_items {
						id
						itemable_id
						itemable_type
						title
						variant_title
						product_id
						variant_id
						image
						type
						uses
						quantity
						price
						default
						one_off
						recipe {
							handle
							protein
							category
							discontinued
							product {
								title
								image
								tags
								variants {
									id
									title
									sku
									weight
									price
								}
							}
						}
						created_at
						updated_at
					}
					secondary_items {
						id
						itemable_id
						itemable_type
						title
						product_id
						variant_id
						image
						type
						uses
						quantity
						price
						default
						one_off
						recipe {
							handle
							protein
							category
							discontinued
							product {
								title
								image
								tags
								variants {
									id
									title
									sku
									weight
									price
								}
							}
						}
						created_at
						updated_at
					}
					delivery_after_next {
						id
						delivery_date
						status
						stripe_error
						total
						delivery
						address_id
						new_address_id
						discount
						processing_date
						is_locked
						scoop
						recycling_label
						discount
						manual
						billing_date
						price
						discount_code
						primary_items {
							id
							itemable_id
							itemable_type
							title
							variant_title
							product_id
							variant_id
							image
							type
							uses
							quantity
							price
							default
							one_off
							recipe {
								handle
								protein
								category
								discontinued
								subtitle
								product {
									title
									image
									tags
									variants {
										id
										title
										sku
										weight
										price
									}
								}
							}
							created_at
							updated_at
						}
						secondary_items {
							id
							itemable_id
							itemable_type
							title
							product_id
							variant_id
							image
							type
							uses
							quantity
							price
							default
							created_at
							updated_at
							one_off
							recipe {
								handle
								protein
								category
								discontinued
								subtitle
								product {
									title
									image
									tags
									variants {
										id
										title
										sku
										weight
										price
									}
								}
							}
						}
					}
					next_delivery {
						id
						delivery_date
						status
						stripe_error
						total
						delivery
						address_id
						new_address_id
						discount
						processing_date
						is_locked
						scoop
						recycling_label
						discount
						manual
						billing_date
						price
						discount_code
						primary_items {
							id
							itemable_id
							itemable_type
							title
							variant_title
							product_id
							variant_id
							image
							type
							uses
							quantity
							price
							default
							one_off
							recipe {
								handle
								protein
								category
								discontinued
								subtitle
								product {
									title
									image
									tags
									variants {
										id
										title
										sku
										weight
										price
									}
								}
							}
							created_at
							updated_at
						}
						secondary_items {
							id
							itemable_id
							itemable_type
							title
							product_id
							variant_id
							image
							type
							uses
							quantity
							price
							default
							created_at
							updated_at
							one_off
							recipe {
								handle
								protein
								category
								subtitle
								discontinued
								product {
									title
									image
									tags
									variants {
										id
										title
										sku
										weight
										price
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
	mutation updateCustomer(
		$shopify_id: String
		$item_id: Int
		$customer: CustomerInput!
	) {
		UpdateCustomer(
			shopify_id: $shopify_id
			item_id: $item_id
			input: $customer
		) {
			shopify_id
			email
			first_name
			last_name
			phone
			default_shipping_method
			available_shipping_methods
		}
	}
`;
