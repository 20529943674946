import * as Sentry from "@sentry/node";
import {
intervalToDuration, parseISO 
} from "date-fns";
import { ApolloError } from "@apollo/client";

import {
	CustomerAPIResponse,
	CustomerDeliveryAPIResponse,
	CustomerDeliveryTrackingInfoAPIResponse,
	CustomerPetHealthReportsResponse,
	CustomerPetSuitableRecipeResponse,
	RAFInformation,
	RAFInformationAPIResponse,
} from "@/types/Account";
import { CustomerDeliveryTrackingInfo } from "@/types/AccountLocal";

import { initializeApollo } from "./apolloClient";
import {
	ACCESS_MUTATION,
	CUSTOMER_REFERRAL_SCHEMES,
	DELETE_ADDRESS_MUTATION,
	DELETE_CUSTOMER_CARD,
	GET_CUSTOMER_QUERY,
	GET_FULL_CUSTOMER_QUERY,
	GET_ORDER_TRACKING_INFORMATION,
	PROCESS_DELIVERY_MUTATION,
	RECOVER_MUTATION,
	RESET_PASSWORD_MUTATION,
	SEND_FEEDBACK_MUTATION,
	SET_PASSWORD_MUTATION,
	STRIPE_MUTATION,
	STRIPE_QUERY,
	UPDATE_ADDRESS_MUTATION,
	UPDATE_DELIVERY_MUTATION,
	UPDATE_ITEMS_MUTATION,
	UPDATE_PET_MUTATION,
	UPDATE_RETENTION_REASON,
	UPDATE_SUBSCRIPTION_MUTATION,
} from "./queries";
import {
	GET_CUSTOMER_DELIVERIES_QUERY,
	GET_CUSTOMER_HEALTH_REPORTS,
	GET_CUSTOMER_SUITABLE_RECIPES_QUERY,
	GET_SINGLE_CUSTOMER_DELIVERY_QUERY,
	UPDATE_CUSTOMER_MUTATION,
} from "./queries/accountData";
import { GENERATE_LOGIN_TOKEN } from "./queries/login";

import { NEW_PLAN_SIZES, OLD_PLAN_SIZES } from "../consts";
import { track } from "../helpers";

const catchError = (e: any) => {
	typeof Sentry !== "undefined" &&
		Sentry?.captureException(new Error(e.message));

	track("Error occurred fetching data from API", {
		error: e.message
	})

	return {
		error:
			"Sorry, something went wrong. Please try again or contact support if the issue persists",
	};
};

const tokenIsMissing = (token: string, callback: () => void) => {
	if (!token) {
		callback();

		return {
			error: "Token is missing",
			data: null
		}
	}

	return false;
}

type FULL_CUSTOMER_API_RESPONSE = {
	data: {
		customer: CustomerAPIResponse;
	};
	loading: boolean;
	networkStatus: number;
};

type getCustomerDataReturn = {
	data: CustomerAPIResponse,
	error: null
} | {
	data: null,
	error: string;
}

export const getCustomerData = async (
	id: number,
	cacheBusted = false,
	token: string,
	onError: () => void
): Promise<getCustomerDataReturn> => {

	const tokenCheck = tokenIsMissing(token, onError);

	if (tokenCheck) {
		return tokenCheck;
	}

	// Force it to be an int
	const customerVariables = {
		id: parseInt(`${id}`),
		token: token
	};

	let fetchPolicy = "cache-first";
	if (cacheBusted) {
		fetchPolicy = "no-cache";
	}

	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_FULL_CUSTOMER_QUERY,
			variables: customerVariables,
			fetchPolicy: fetchPolicy,
			context: { clientName: "subApp" },
		})
		.then((result: FULL_CUSTOMER_API_RESPONSE) => {
			if (!result.data.customer) {
				return {
					error: "An error occurred",
					data: null,
				}
			}

			return { 
				data: result.data?.customer,
				error: null 
			};
		})
		.catch((e: any) => {
			catchError(e); 
			onError();
		});
};

type CUSTOMER_FEEDBACK_RESPONSE = {
	data: {
		CreateFeedback: {
			attached_id: number;
			attached_type: string;
			created_at: string;
			feedback_description: string;
			feedback_name: string;
			id: number;
			updated_at: string;
		};
	};
};

export const sendCustomerFeedback = async (
	customerId: number,
	subscriptionId: number,
	feedbackCategory:
		| "feature suggestion"
		| "cancellation reason"
		| "cancellation feedback"
		| "not suited reason",
	feedbackDescription: string
): Promise<{
	error: boolean;
	data: CUSTOMER_FEEDBACK_RESPONSE["data"]["CreateFeedback"];
}> => {
	const customerVariables = {
		customer_id: customerId,
		subscription_id: subscriptionId,
		input: {
			feedback_name: feedbackCategory,
			feedback_description: feedbackDescription,
		},
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: SEND_FEEDBACK_MUTATION,
			variables: customerVariables,
			context: { clientName: "subApp" },
		})
		.then((result: CUSTOMER_FEEDBACK_RESPONSE) => {
			if (!result.data.CreateFeedback) {
				return {
					error: true,
					data: null
				};
			}

			return {
				error: false,
				data: result.data?.CreateFeedback
			};
		})
		.catch(catchError);
};

export type RetentionReasons =
	| "Transition tips"
	| "Pricing tips"
	| "Intro tips"
	| "Deliveries tips"
	| "Taste tips"
	| "Health tips"
	| "Switched to new recipe"
	| "Taking transition slower"
	| "Pushed next delivery"
	| "Changed to partial plan"
	| "Gave discount"
	| "Upsold to bigger bag"
	| "Adjusted ongoing frequency"
	| "Not yet started transition"
	| "Changed delivery provider"
	| "Try recipe sample"
	| "Contact CS";

export type CancellationReasons =
	| "price"
	| "passed"
	| "order too late"
	| "delivery provider"
	| "taste"
	| "health"
	| "not suitable"
	| "something else"
	| "none provided"
	| "final discount";

type CUSTOMER_RETENTION_RESPONSE = {
	data: {
		UpdateRetention: {
			id: number;
			subscription_id: number;
			cancellation_reason: CancellationReasons;
			retained_by: string | null;
			retention_reasons: RetentionReasons[];
			notes: string | null;
			retained_via: string;
			created_at: string;
			updated_at: string;
		};
	};
};

export const sendCustomerRetentionReason = async (
	subscriptionId: number,
	retentionReasons: RetentionReasons[],
	cancellationReason: CancellationReasons
): Promise<{ success: boolean }> => {
	const customerVariables = {
		subscription_id: subscriptionId,
		input: {
			retention_reasons: retentionReasons,
			cancellation_reason: cancellationReason.toLowerCase(),
			retained_via: "Website",
			notes: null,
			retainer: null,
		},
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: UPDATE_RETENTION_REASON,
			variables: customerVariables,
			context: { clientName: "subApp" },
		})
		.then((result: CUSTOMER_RETENTION_RESPONSE) => {
			if (!result.data?.UpdateRetention) {
				return { success: false };
			}

			return { success: true };
		})
		.catch(catchError);
};

type CUSTOMER_DELIVERY_RESPONSE = {
	data: {
		customer: CustomerPetSuitableRecipeResponse;
	};
};


type getSuitableRecipesReturn = {
	data: CustomerPetSuitableRecipeResponse,
	error: null
} | {
	data: null,
	error: string;
}

export const getSuitableRecipes = async (
	id: number,
	cacheBusted = false,
	token: string,
	onError: () => void
): Promise<getSuitableRecipesReturn> => {
	const tokenCheck = tokenIsMissing(token, onError);
	
	if (tokenCheck) {
		return tokenCheck;
	}

	const customerVariables = {
		id: parseInt(`${id}`),
		token: token
	};

	let fetchPolicy = "cache-first";
	if (cacheBusted) {
		fetchPolicy = "no-cache";
	}

	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_CUSTOMER_SUITABLE_RECIPES_QUERY,
			variables: customerVariables,
			fetchPolicy: fetchPolicy,
			context: { clientName: "subApp" },
		})
		.then((result: CUSTOMER_DELIVERY_RESPONSE) => {
			if (!result.data.customer) {
				return {
					data: null,
					error: "An error occurred",
				}
			}

			return {
				data: result.data?.customer,
				error: null
			}
		})
		.catch((e: any) => {
			catchError(e);

			return {
				data: null,
				error: "An error occurred"
			}
		});
};

export const getHealthReports = async (
	id: number,
	cacheBusted = false
): Promise<CustomerPetHealthReportsResponse> => {
	const customerVariables = {
		id,
	};

	let fetchPolicy = "cache-first";
	if (cacheBusted) {
		fetchPolicy = "no-cache";
	}

	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_CUSTOMER_HEALTH_REPORTS,
			variables: customerVariables,
			fetchPolicy: fetchPolicy,
			context: { clientName: "subApp" },
		})
		.then((result: CUSTOMER_DELIVERY_RESPONSE) => {
			if (!result.data.customer) {
				return;
			}

			return result.data?.customer;
		})
		.catch(catchError);
};

export const getCustomerDelivery = async (
	deliveryID: number,
	cacheBusted = false
): Promise<CustomerDeliveryAPIResponse> => {
	const customerVariables = {
		delivery_id: deliveryID,
	};

	let fetchPolicy = "cache-first";
	if (cacheBusted) {
		fetchPolicy = "no-cache";
	}

	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_SINGLE_CUSTOMER_DELIVERY_QUERY,
			variables: customerVariables,
			fetchPolicy: fetchPolicy,
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			if (!result.data.singleDelivery) {
				return null;
			}

			return result.data?.singleDelivery as CustomerDeliveryAPIResponse;
		})
		.catch((all: any) => {
			console.log("erroring", all); //catchError
		});
};

type getCustomerDeliveriesReturn = {
	data: CustomerDeliveryAPIResponse,
	error: null
} | {
	data: null,
	error: string;
}

export const getCustomerDeliveries = async (
	id: number,
	cacheBusted = false,
	token: string,
	onError: () => void
): Promise<getCustomerDeliveriesReturn> => {
	const tokenCheck = tokenIsMissing(token, onError);
	
	if (tokenCheck) {
		return tokenCheck;
	}
	
	const customerVariables = {
		id: parseInt(`${id}`),
		token: token
	};

	let fetchPolicy = "cache-first";
	if (cacheBusted) {
		fetchPolicy = "no-cache";
	}

	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_CUSTOMER_DELIVERIES_QUERY,
			variables: customerVariables,
			fetchPolicy: fetchPolicy,
			errorPolicy: "all",
			context: { clientName: "subApp" },
		})
		.then((result: CUSTOMER_DELIVERY_RESPONSE) => {
			if (!result.data?.customer) {

				return {
					data: null,
					error: "Error occurred"
				}
			}

			return { 
				data: result.data?.customer,
				error: null 
			};
		})
		.catch((e: any) => {
			
			catchError(e);

			return {
				data: null,
				error: "Token is missing"
			}
		});
};

type MutateCustomerInformationResponse = {
	errors?: {
		locations: { line: number; column: number }[];
		message: "string";
		validation: Record<string, string[]>;
	}[];
	data: {
		UpdateCustomer: {
			email: string;
			first_name: string;
			phone: string;
			last_name: string;
			shopify_id: string;
		};
	};
};

export const mutateCustomerInformation = async (
	shopify_id: string,
	item_id: number,
	{
		paymentMethod,
		...data
	}: {
		paymentMethod?: {
			expiry_date: any;
			last_four_digits: any;
			brand: any;
			country: any;
			funding: any;
			payment_id: any;
		};
		default_shipping_method?: string;
		first_name?: string;
		last_name?: string;
		phone?: string;
		email?: string;
	}
): Promise<{
	data: null | MutateCustomerInformationResponse["data"]["UpdateCustomer"];
	error: string | null;
}> => {
	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: UPDATE_CUSTOMER_MUTATION,
			errorPolicy: "all",
			variables: {
				shopify_id,
				item_id: parseInt(`${item_id}`),
				customer: {
					...data,
					shopify_id,
					id: parseInt(`${item_id}`),
					...(paymentMethod
						? { paymentMethod }
						: {}),
				},
			},
			context: { clientName: "subApp" },
		})
		.then((result: MutateCustomerInformationResponse) => {
			if (result.errors && result.errors?.length > 0) {
				if (
					result.errors[0].validation["input.email"]?.[0] ===
					"validation.email_unique"
				) {
					return {
						error: "This email address is already in use.",
						data: null
					};
				}

				if (
					result.errors[0].validation["input.email"]?.[0] ===
					"The input.email must be a valid email address."
				) {
					return {
						error: "Please enter a valid email address.",
						data: null
					};
				}

				if (
					result.errors[0].validation["input.phone"]?.[0] ===
					"validation.phone_unique"
				) {
					return {
						error: "This contact number is already in use.",
						data: null,
					};
				}

				if (
					result.errors[0].validation["input.phone"]?.[0] === "validation.phone"
				) {
					return {
						error: "Please enter a valid contact number and try again.",
						data: null,
					};
				}

				return {
					error:
						"An error occurred, please check the information you have entered and try again.",
					data: null,
				};
			}

			return {
				data: result.data.UpdateCustomer,
				error: null
			};
		})
		.catch(catchError);
};

type MutatePetInformationResponse = {
	data: {
		UpdatePet: {
			email: string;
			first_name: string;
			phone: string;
			last_name: string;
			shopify_id: string;
		};
	};
};

export async function deleteAddress (customerId: number, addressId: number) {
	const variables = {
		customer_id: customerId,
		item_id: addressId,
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: DELETE_ADDRESS_MUTATION,
			variables: variables,
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			if (result.data.errors && result.data.errors.length > 1) {
				return {
					error: true,
					data: null,
				};
			}

			return {
				error: false,
				data: result.data,
			};
		})
		.catch((e: any) => {
			console.error(e);

			return {
				error: "Address could not be updated",
			};
		});
}

export async function addAddress (
	customer_id: number,
	address: {
		city: string;
		line_1: string;
		line_2: string;
		province: string;
		postcode: string;
	}
) {
	const variables = {
		customer_id,
		data: {
			...address,
			country: "United Kingdom"
		},
	};
	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: UPDATE_ADDRESS_MUTATION,
			variables: variables,
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			return result.data;
		})
		.catch((_e: any) => {
			return {
				customerUserErrors: [{
					message:
						"Please ensure all required (*) fields are filled in and the address is a valid UK address.",
				},],
			};
		});
}

export async function mutateAddress (
	customer_id: number,
	item_id: string,
	address: {
		city: string;
		line_1: string;
		line_2: string;
		province: string;
		postcode: string;
	}
) {
	const variables = {
		customer_id,
		item_id: parseInt(`${item_id}`),
		data: {
			...address,
			country: "United Kingdom"
		},
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: UPDATE_ADDRESS_MUTATION,
			variables: variables,
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			return result.data;
		})
		.catch(() => {
			return {
				customerUserErrors: [{
					message:
						"Please ensure all required (*) fields are filled in and the address is a valid UK address.",
				},],
			};
		});
}

export async function getReferralSchemes (
	id: string,
	cacheBusted = false,
	token?: string,
): Promise<RAFInformation> {
	const queryVariables = {
		id: parseInt(`${id}`),
		token: token
	};

	let fetchPolicy = "cache-first";

	if (cacheBusted) {
		fetchPolicy = "no-cache";
	}

	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: CUSTOMER_REFERRAL_SCHEMES,
			variables: queryVariables,
			fetchPolicy: fetchPolicy,
			context: { clientName: "subApp" },
		})
		.then((result: {data: RAFInformationAPIResponse}): RAFInformation => {

			const expiryDate = result.data.customer.referral.schemes?.referralScheme?.end_date 
				? new Date(result.data.customer.referral.schemes.referralScheme.end_date) 
				: null;

			const timeToEnd = expiryDate 
				? intervalToDuration({
					start: new Date(),
					end: expiryDate
				}) 
				: null;

			// May not happen 
			if (timeToEnd && timeToEnd.months && timeToEnd.months > 0) {
				// dateFns assumes 1 month is 30 days, we need the days count
				// would usually avoid mutation at all costs, but this is fine i think... think...
				timeToEnd.days = ( timeToEnd.days || 0 ) + timeToEnd.months * 30
			}

			if (!result.data.customer.referral.schemes?.referralScheme) {
				console.error("Customer is missing referral scheme from data");
			}

			return {
				credit: result.data.customer.referral.credit,
				customers_count: result.data.customer.referral.customers_count,
				scheme: { 
						...result.data.customer.referral.schemes,

						referralScheme: {
							...(result.data.customer.referral.schemes.referralScheme || {}),
							endDate: result.data.customer.referral.schemes?.referralScheme?.end_date 
								? new Date(result.data.customer.referral.schemes.referralScheme.end_date) 
								: null,
							timeToEnd: timeToEnd
						}
					}
			}
		})
		.catch((e: any) => {
			console.error(e);

			return e;
		});
}

export async function recoverUser (email: string) {
	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: RECOVER_MUTATION,
			errorPolicy: "all",
			variables: {
				email: email,
			},
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			if (result.errors) {
				return {
					data: null,
					error: "This email address could not found.",
				};
			}

			return {
				data: result.data,
				error: null,
			};
		})
		.catch((e: any) => {
			console.error(e);

			return {
				error:
					"Sorry, something went wrong. Please try again or contact support if the issue persists",
				data: null,
			};
		});
}

export async function resetPassword (
	email: string,
	new_password: string,
	token: string
) {
	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: RESET_PASSWORD_MUTATION,
			errorPolicy: "all",
			variables: {
				resetPassword: {
					email,
					new_password,
					token,
				},
			},
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			if (result.errors && result.errors.length > 0) {
				return { 
					customerUserErrors: [{
						message:
							Object.values(result.errors[0]?.validation).flatMap((msg) => msg)
								?.join(" "),
					},] 
				}
			}
			
			return result.data;
		})
		.catch((e: any) => {
			if (
				e?.graphQLErrors?.[0]?.validation?.[
					"resetPassword.new_password"
				]?.[0] === "validation.password_same"
			) {
				return {
					customerUserErrors: [{
						message:
							"The password entered is the same as your current password. Please enter a new password.",
					},],
				};
			}

			return {
				customerUserErrors: [{
					message:
						"Sorry, something went wrong. Please try again or contact support if the issue persists",
				},],
			};
		});
}

export const mutatePet = async (userID: string, petID: number, data: any) => {
	const updateVariables = {
		shopify_id: userID,
		item_id: petID,
		data: data,
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: UPDATE_PET_MUTATION,
			variables: updateVariables,
			context: { clientName: "subApp" },
		})
		.then(({ data, ...rest }: MutatePetInformationResponse) => {
			if (data?.UpdatePet) {
				return data.UpdatePet;
			} else {
				console.error({
					...data,
					...rest
				});

				return {
					error:
						"Sorry, something went wrong. Please try again or contact support if the issue persists",
				};
			}
		})
		.catch(catchError);
};

type UpdateCustomerCardDetailsResponse = {
	data: any;
};

export const updateCustomerCardDetails = async (
	customerStripeID: string,
	payment_id: string,
	customerId: number
) => {
	const apolloClient = initializeApollo();

	const updateVariables = {
		data: {
			payment_id,
			customer: customerStripeID,
			customer_id: customerId,
		},
	};

	return await apolloClient
		.mutate({
			mutation: STRIPE_MUTATION,
			variables: updateVariables,
			context: { clientName: "subApp" },
		})
		.then(
			({ data }: UpdateCustomerCardDetailsResponse) => data.UpdateCustomerCard
		)
		.catch(catchError);
};

type DeleteCustomerCardDetailsResponse = {
	data: any;
};

export const deleteCustomerCardDetails = async (
	customerStripeID: string,
	payment_id: string,
	customerId: number
) => {
	const apolloClient = initializeApollo();

	const deleteVariables = {
		data: {
			payment_id,
			customer: customerStripeID,
			customer_id: customerId,
		},
	};

	return await apolloClient
		.mutate({
			mutation: DELETE_CUSTOMER_CARD,
			variables: deleteVariables,
			context: { clientName: "subApp" },
		})
		.then(
			({ data }: DeleteCustomerCardDetailsResponse) => data.DeleteCustomerCard
		)
		.catch(catchError);
};

type StripeIntentResponse = {
	data: any;
};

export const getStripeIntent = async (stripeID?: string) => {
	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			variables: {
				customer: stripeID,
			},
			query: STRIPE_QUERY,
			context: { clientName: "subApp" },
			fetchPolicy: "no-cache",
		})
		.then(({ data }: StripeIntentResponse) => data.stripeIntent)
		.catch(catchError);
};

type MutateDeliveryResult = {
	delivery_date: string;
	error: null | string;
	id: number;
	scoop: boolean;
	status:
	| "transition"
	| "cancelled"
	| "delayed-transition"
	| "delayed-transition-completed"
	| "completed"
	| "skipped"
	| "failed"
	| "queued";
};

export const mutateDelivery = async (
	data: Record<string, unknown>,
	userID: string,
	deliveryID: number,
	clear_error = false
): Promise<MutateDeliveryResult> => {
	const updateVariables = {
		shopify_id: userID,
		item_id: deliveryID,
		data: data,
		clear_error,
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: UPDATE_DELIVERY_MUTATION,
			variables: updateVariables,
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			if (result.data && result.data.UpdateDelivery) {
				return result.data.UpdateDelivery;
			}

			console.error(result);

			return {
				error:
					"Sorry, something went wrong. Please try again or contact support if the issue persists",
			};
		})
		.catch(catchError);
};

export function packPrice (
	variants: any,
	baseSize: any,
	basePrice: any,
	newSize: any
) {
	const oldSizes = OLD_PLAN_SIZES;
	const newSizes = NEW_PLAN_SIZES;

	if (!variants) {
		return null;
	}

	const sizeOptions = variants
		.filter((variant: any) => {
			if (
				oldSizes.includes(baseSize) &&
				oldSizes.includes(parseInt(variant.title))
			) {
				return true;
			} else if (
				newSizes.includes(baseSize) &&
				newSizes.includes(parseInt(variant.title))
			) {
				return true;
			}

			return false;
		})
		.sort((a: any, b: any) => (a.title > b.title
			? 1
			: -1))
		.map((variant: any) => {
			return parseInt(variant.title);
		});

	const baseIndex = sizeOptions.indexOf(baseSize);
	const newIndex = sizeOptions.indexOf(newSize);
	const multiplier = newIndex - baseIndex;
	let discountPerc;
	if (oldSizes.includes(baseSize)) {
		discountPerc = Math.sign(multiplier) === 1
			? 0.05
			: 0.18;
	} else {
		discountPerc = Math.sign(multiplier) === 1
			? 0.05
			: 0.12;
	}

	const price = ((basePrice / baseSize) * newSize) / 100;

	return {
		price: price,
		discount: multiplier * discountPerc * price,
	};
}

export const mutateItems = async (
	data: any,
	userID: string,
	subID: number,
	deliveryID?: number
) => {
	const updateVariables = {
		shopify_id: userID,
		subscription_id: subID,
		data: data,
		delivery_id: deliveryID,
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: UPDATE_ITEMS_MUTATION,
			variables: updateVariables,
			errorPolicy: "all",
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			if (result.data && result.data.UpdateItems) {
				return result.data.UpdateItems;
			} else {
				throw new Error(result.errors?.[0]?.message);
			}
		})
		.catch((e: any) => {
			typeof Sentry !== "undefined" &&
				Sentry?.captureException(new Error(e.message));

			return {
				error:
					"Sorry, something went wrong. Please try again or contact support if the issue persists",
			};
		});
};

type ProcessDeliveryResult = {
	data: {
		ProcessDelivery: {
			id: number;
			is_locked: boolean;
			delivery_date: string;
			error: string | null;
			stripe_error: string | null;
		};
	};
};

export const fetchOrderTrackingInfo = async (
	delivery_id: string
): Promise<CustomerDeliveryTrackingInfo> => {
	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_ORDER_TRACKING_INFORMATION,
			variables: {
				delivery_id: parseInt(delivery_id),
			},
			fetchPolicy: "no-cache",
			errorPolicy: "all",
			context: { clientName: "production" },
		})
		.then(
			(
				result: CustomerDeliveryTrackingInfoAPIResponse
			): CustomerDeliveryTrackingInfo => {
				const status =
					typeof result?.data?.orderStatus?.status !== "string"
						? result?.data?.orderStatus?.status || null
						: (result?.data?.orderStatus?.status?.replace(
							/-/g,
							" "
						) as CustomerDeliveryTrackingInfo["status"]);

				return {
					status,
					courier: result?.data?.orderStatus?.courier || null,
					created_at: result.data.orderStatus?.created_at
						? new Date(result.data.orderStatus.created_at)
						: null,
					packed_at: result.data.orderStatus?.packed_at
						? new Date(result.data.orderStatus.packed_at)
						: null,
					ship_date: result.data.orderStatus?.ship_date
						? new Date(result.data.orderStatus.ship_date)
						: null,
					status_changed_at: result.data.orderStatus?.status_changed_at
						? parseISO(result.data.orderStatus.status_changed_at)
						: null,
					late: result.data.orderStatus?.late || null,
					latest_event_code: result.data.orderStatus?.latest_event_code || null,
					customer_tracking_number:
						result.data.orderStatus?.customer_tracking_number || null,
				};
			}
		)
		.catch((error: any): { error: string } => {
			console.error(error);

			return {
				error: "An error occurred",
			};
		});
};

export const processDelivery = async (
	customer_id: number,
	item_id: number,
	dontSetErrorMessage = false
) => {
	const updateVariables = !dontSetErrorMessage
		? {
			customer_id: parseInt(`${customer_id}`),
			item_id,
		}
		: {
			no_error: true,
			customer_id: parseInt(`${customer_id}`),
			item_id,
		};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: PROCESS_DELIVERY_MUTATION,
			variables: updateVariables,
			errorPolicy: "all",
			context: { clientName: "subApp" },
		})
		.then((result: ProcessDeliveryResult) => {
			if (result.data.ProcessDelivery.stripe_error) {
				return {
					data: null,
					error: result.data.ProcessDelivery.stripe_error,
				};
			}

			if (result.data.ProcessDelivery.error) {
				return {
					data: null,
					error: "Failed to process delivery.",
				};
			}

			return {
				data: result.data.ProcessDelivery,
				error: null,
			};
		})
		.catch(catchError);
};

type MutateSubscriptionResult = {
	data: {
		UpdateSubscription: any;
	};
	errors: any;
};

export const mutateSubscription = async (
	data: Record<string, unknown>,
	userId: string,
	subscriptionId: number
) => {
	const updateVariables = {
		shopify_id: userId,
		item_id: subscriptionId,
		data: data,
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: UPDATE_SUBSCRIPTION_MUTATION,
			variables: updateVariables,
			errorPolicy: "all",
			context: { clientName: "subApp" },
		})
		.then((result: MutateSubscriptionResult) => {
			if (result.data && result.data.UpdateSubscription) {
				return result.data.UpdateSubscription;
			}

			return {
				error: result.errors?.[0]?.message,
			};
		})
		.catch(catchError);
};

export async function loginUserOnShopify (email: string, password: string) {
	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: ACCESS_MUTATION,
			variables: {
				input: {
					email: email,
					password: password,
				},
			},
			context: { clientName: "shopify" },
		})
		.then((result: any) => result.data.customerAccessTokenCreate)
		.catch((e: any) => {
			console.error("ERROR", e);

			return e;
		});
}

export async function setupLoginUserShopify (accessToken: string) {
	const customerVariables = {
		accessToken: accessToken,
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_CUSTOMER_QUERY,
			variables: customerVariables,
			context: { clientName: "shopify" },
		})
		.then((result: any) => {
			return result.data.customer;
		})
		.catch((error: any) => {
			console.error(error);

			return {
				customerUserErrors: [{
					message:
						"Sorry, something went wrong. Please try again or contact support if the issue persists",
				},],
			};
		});
}

export async function setUserPassword (
	new_password: string,
	email: string,
	shopify_id: string
) {
	const customerVariables = {
		setPassword: {
			new_password,
			email,
			shopify_id
		},
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: SET_PASSWORD_MUTATION,
			variables: customerVariables,
			errorPolicy: "all",
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			return {
				error: null,
				data: result.data,
			};
		})
		.catch((error: ApolloError) => {
			console.error(error);

			return {
				error:
					"Sorry, something went wrong. Please try again or contact support if the issue persists",
				data: null,
			};
		});
}

export async function getLoginToken (
	id: number,
	first_name: string,
	last_name: string,
	email: string
){
	const variables = {
		id: id,
		first_name: first_name,
		last_name: last_name,
		email: email
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: GENERATE_LOGIN_TOKEN,
			variables: variables,
			context: { clientName: "subApp" },
		})
		.then((result: any) => {
			return {
				error: null,
				data: result.data,
			};
		})
		.catch((error: ApolloError) => {
			console.log(error);
		})
}
