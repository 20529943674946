import { getPronouns } from "@/hooks/usePersonalPronouns";

import { Treat, UnsuitableRecipeOverview } from "./data";
import {
	ActivityLevelTime,
	BehaviouralProblems,
	Benefits,
	BodyShape,
	Coat,
	Digestion,
	FussyLevel,
	Gender,
	LifeStages,
	Mobility,
	OralHealth,
	Stools,
	Tags,
	TreatsQuantity,
} from "./general";

export type PlanDetails = {
	planLength: number;
	planPrice: number;
	trialSize: number;
	planSize: number;
	planScoops: number;
	selectedPlan: string;
};

export type Content = {
	product: {
		image: string;
		title: string;
		variants: { weight: number; price: number; title: string; id: string }[];
		handle: string;
	};
};

export type AlternatePlanSize = {
	size: number;
	price: number;
	discount: number;
	frequency: number;
	scoops: number;
};

export type Voucher = {
	type_limit?: "subscription" | "non_transition" | "all";
	type: "fixed" | string;
	active?: boolean;
	amount: number;
	code?: string;
	description?: string;
	future_discounts?: Voucher[];
	set_limit_amount: string;
	only_apply_to_primary_items?: boolean;
	only_apply_to_secondary_items?: boolean;
	start_date: Date;
	end_date: Date;
	availableAfterSignUp: number;
};

export type Referral = {
	token: string;
	name: string;
	pets: string[];
	scheme: {
		name: string;
		description: string;
		amount: number;
		discountCode: Voucher;
	};
};

export type Pet = {
	hasSelectedPlan: boolean;
	iteration: number;
	index: number;
	pronouns: ReturnType<typeof getPronouns>;
	petId: string;
	treats?: Treat[];
	breedCount: number;
	availableTreats?: string[];
	planSize?: number;
	planScoops?: number;
	selectedPlan?: "standard" | "partial" | "partial large" | "large";
	birthday?: string;
	activity: ActivityLevelTime;
	shape: BodyShape;
	adultAge: number;
	adultWeight: number;
	age: number;
	allergies: string[];
	benefits?: Benefits;
	breed: string;
	calories: number;
	diet: string[];
	fussy: FussyLevel;
	gender: "male" | "female";
	goal: string[];
	healthIssues: string[];
	healthIssuesLong: string[];
	lifestage: LifeStages;
	name: string;
	neutered: boolean;
	oldPuppyAge: null | number;
	recipe: string;
	scoopsFood: number;
	scoopsWater: number;
	adultScoops?: number;
	selectedRecipes: string[];
	shuffledRecipes: string[];
	shuffledUnsuitableRecipes: UnsuitableRecipeOverview[];
	allergens?: string[];
	breeds?: string[];
	conditions?: string[];
	handle?: string;
	subLength: number;
	basePrice: number;
	baseSize: number;
	basePuppySize: number | null;
	basePuppyPrice: number | null;
	tags: Tags[];
	treatsFed: TreatsQuantity;
	trialLength: number;
	trialSize: number;
	weight: number;
	price_test?: string;
	planPrice?: number;
	planLength?: number;
	improveHealth?: boolean;
	workingDog?: boolean;
	subscription?: {
		scoops_food: number;
		scoops_water: number;
		length: number;
		trial_length: number;
		price_test: string | null;
		base_size: number;
		selected_plan: string;
	};
	coat?: Coat[];
	mobility?: Mobility[];
	oral?: OralHealth[];
	stools?: Stools;
	behavioural?: BehaviouralProblems[];
	digestion?: Digestion[];
	fussySalmonOil?: string | null;
};

export enum Fields {
	PETID = "petId",
	NAME = "name",
	ACTIVITY = "activity",
	ADULTAGE = "adultAge",
	ADULTWEIGHT = "adultWeight",
	HEALTHISSUES = "healthIssues",
	ALLERGIES = "allergies",
	BREED = "breed",
	DIET = "diet",
	EMAIL = "email",
	MARKETINGOPTIN = "marketingOptIn",
	ESTIMATEDWEIGHT = "estimatedWeight",
	FIRSTNAME = "firstName",
	FUSSY = "fussy",
	GENDER = "gender",
	GOAL = "goal",
	LIFESTAGE = "lifestage",
	MONTHS = "months",
	NEUTERED = "neutered",
	OLDPUPPYAGE = "oldPuppyAge",
	SHAPE = "shape",
	TRANSFORMEDAGE = "transformedAge",
	TREATS = "treats",
	WEIGHT = "weight",
	YEARS = "years",
	BREEDS = "breeds",
	IMPROVEHEALTH = "improveHealth",
	COAT = "coat",
	MOBILITY = "mobility",
	DIGESTION = "digestion",
	ORAL = "oral",
	STOOLS = "stools",
	BEHAVIOURAL = "behavioural",
	WORKINGDOG = "workingDog",
	BREEDCOUNT = "breedCount",
	PRONOUNS = "pronouns",
	CREATEDAT = "createdAt"
}

export type FunnelData = {
	[Fields.PETID]: string;
	[Fields.NAME]: string;
	[Fields.PRONOUNS]?: ReturnType<typeof getPronouns>;
	[Fields.ACTIVITY]?: ActivityLevelTime;
	[Fields.ADULTAGE]?: number;
	[Fields.ADULTWEIGHT]?: number;
	[Fields.HEALTHISSUES]?: string[] | ["none"];
	[Fields.ALLERGIES]?: string[] | ["none"];
	[Fields.BREED]?: string;
	[Fields.DIET]?: string[];
	[Fields.FUSSY]?: FussyLevel;
	[Fields.GENDER]?: Gender;
	[Fields.GOAL]?: string[];
	[Fields.LIFESTAGE]?: LifeStages;
	[Fields.MONTHS]?: number;
	[Fields.NEUTERED]?: "yes" | "no"; // TODO refactor to boolean
	[Fields.OLDPUPPYAGE]?: number;
	[Fields.SHAPE]?: BodyShape;
	[Fields.TRANSFORMEDAGE]?: number;
	[Fields.TREATS]?: TreatsQuantity;
	[Fields.WEIGHT]?: number;
	[Fields.YEARS]?: number;
	[Fields.ESTIMATEDWEIGHT]?: number;
	[Fields.IMPROVEHEALTH]?: boolean;
	[Fields.COAT]?: Coat[];
	[Fields.MOBILITY]?: Mobility[];
	[Fields.ORAL]?: OralHealth[];
	[Fields.STOOLS]?: Stools;
	[Fields.BEHAVIOURAL]?: BehaviouralProblems[];
	[Fields.DIGESTION]?: Digestion[];
	[Fields.WORKINGDOG]?: boolean;
	[Fields.BREEDCOUNT]?: number;
	[Fields.CREATEDAT]: Date;
};

export type FunnelDataComplete = Required<FunnelData>;
